export function gtag_report_conversion(url?) {
    // Safety check to ensure we're in the browser and gtag is available
    if (typeof window === 'undefined' || !window.gtag) {
      return;
    }
  
    const callback = function () {
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    };
  
    window.gtag('event', 'conversion', {
      'send_to': 'AW-16680283756/KpvvCJyKfaIDocz45e', // Your conversion ID
      'value': 50000.00,
      'currency': 'KRW',
      'event_callback': callback
    });
  }