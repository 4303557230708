import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import SEO from '../components/base/SEO'
import Layout from '../components/base/Layout'
import MedicalCTASection from '../components/products/MedicalCTASection'
import InvertColorsContext from '../context/InvertColorsContext'
import useWindowSize from '../lib/hooks/useWindowSize'
import DataVoucherIntroSection from '../components/dataVoucher/DataVoucherIntroSection'
import DataVoucherSecondSection from '../components/dataVoucher/DataVoucherSecondSection'
import DataVoucherThirdSection from '../components/dataVoucher/DataVoucherThirdSection'
import DataVoucherFourthSection from '../components/dataVoucher/DataVoucherFourthSection'
import DataVoucherFifthSection from '../components/dataVoucher/DataVoucherFifthSection'
import DataVoucherSixthSection from '../components/dataVoucher/DataVoucherSixthSection'
import DataVoucherSeventhSection from '../components/dataVoucher/DataVoucherSeventhSection'
import DataVoucherContactSection from '../components/dataVoucher/DataVoucherContactSection'

const metaData = {
	title: '데이터바우처 | Tesser',
	description: '의료 인공지능을 위한 데이터 바우처. 테서와 함께하세요. ',
	keywords:
		'의료데이터, 임상데이터, 데이터바우처, 의료, 병원, 헬스케어, AI, 데이터, 바우처, 가공, 분석, 데이터헌트, datahunt, 데이터바우처 사업',
}

const checkIsBrowserSafariAndNotMobile = () => {
	const agent = 'win16|win32|win64|macintel|mac'
	const isClient = typeof window !== 'undefined'

	if (isClient) {
		if (agent.indexOf(navigator.platform.toLowerCase()) < 0) {
			return false
		} else {
			const browser = navigator.vendor.toLowerCase()
			return browser.includes('apple')
		}
	}
	return false
}

function dataVoucher() {
	const windowSize = useWindowSize()
	const [innerHeight, setInnerHeight] = useState<number | null>(null)
	const dataVoucherFlag = {
		isDataVoucher: true,
		isBrowswerSafariNotMobile: checkIsBrowserSafariAndNotMobile(),
	}

	useEffect(() => {
		if (windowSize.height) {
			setInnerHeight(windowSize.height)
		}
	}, [windowSize])

	if (!innerHeight) {
		return (
			<Layout color="black" blackFooter medicalPage>
				<SEO metaData={metaData} />
				<Block></Block>
			</Layout>
		)
	}

	return (
		<Layout
			color="black"
			isDataVoucher={dataVoucherFlag.isDataVoucher}
			isBrowswerSafariNotMobile={dataVoucherFlag.isBrowswerSafariNotMobile}
			blackFooter
			medicalPage
			isInfo={false}
		>
			<SEO metaData={metaData} />
			<InvertColorsContext.Consumer>
				{({ invertColors }) => (
					<DataVoucherBlock>
						<DataVoucherIntroSection />
						<DataVoucherSecondSection />
						<DataVoucherThirdSection />
						<DataVoucherFourthSection />
						<DataVoucherFifthSection />
						<DataVoucherSixthSection />
						<DataVoucherSeventhSection />
						<DataVoucherContactSection />
						{/* <MedicalCTASection
							isBrowswerSafariNotMobile={
								dataVoucherFlag.isBrowswerSafariNotMobile
							}
							invertColors={invertColors}
						/> */}
					</DataVoucherBlock>
				)}
			</InvertColorsContext.Consumer>
		</Layout>
	)
}

const Block = styled.div`
	min-height: 100vh;
	margin: 0 auto;
`

const DataVoucherBlock = styled.div`
	min-height: 100vh;
	margin: 0 auto;

	@media (min-width: 1216px) {
		width: 1440px;
	}
	@media (min-width: 688px) and (max-width: 1216px) {
		width: 100%;
		min-width: 768px;
	}
	@media (max-width: 688px) {
		width: 100%;
	}
`

export default dataVoucher
