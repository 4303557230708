import React from 'react'
import styled from 'styled-components'

function DataVoucherSecondSection() {
	return (
		<Block>
			<TitleSubject>
				<p>
					<b>데이터바우처</b> 4년차 공급기업.
				</p>
				<p>의료, 병원, 헬스케어 데이터 전문기업과 함께 하세요.</p>
			</TitleSubject>
			<TitleDescription>
				K-data(한국데이터산업진흥원)에서 데이터 가공을 지원하는 바우처
				사업입니다. 데이터 가공이 필요한 수요기업은 데이터 가공 서비스를
				제공하는 원하는 공급기업과 매칭되어, <b>최대 4,500만원</b>의 상당의
				데이터 가공 서비스를 받을 수 있습니다.
			</TitleDescription>
			<MobileDescription>최대 4,500만원 지원</MobileDescription>
		</Block>
	)
}

const Block = styled.section`
	background: #1f57f7;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media (min-width: 1280px) {
		height: 596px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		align-items: flex-start;
		padding: 128px 40px;
		gap: 32px;
		height: 552px;
	}
	@media (max-width: 688px) {
		padding-top: 76px;
		padding-bottom: 63px;
		justify-content: space-between;
		height: 327px;
	}
`

const TitleSubject = styled.div`
	font-family: 'Pretendard', 'Inter';
	font-style: normal;
	display: flex;
	flex-direction: column;
	text-align: center;
	color: #ffffff;

	@media (min-width: 1280px) {
		height: 184px;
		align-items: center;
		font-size: 50px;
		line-height: 60px;
		gap: 16px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		align-items: flex-start;
		font-size: 30px;
		line-height: 35px;
	}
	@media (max-width: 688px) {
		width: 328px;
		height: 105px;
		font-size: 25px;
		line-height: 35px;
	}
`

const TitleDescription = styled.div`
	font-family: 'Inter', 'Pretendard';
	font-style: normal;
	font-weight: 400;
	line-height: 36px;
	color: #ffffff;

	@media (min-width: 1280px) {
		font-size: 25px;
		width: 1008px;
		height: 108px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		padding-top: 32px;
		font-size: 22px;
		height: 144px;
	}
	@media (max-width: 688px) {
		display: none;
	}
`

const MobileDescription = styled.div`
	@media (min-width: 1280px) {
		display: none;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		display: none;
	}
	@media (max-width: 688px) {
		font-family: 'Pretendard', 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 20px;
		color: #ffffff;
	}
`

export default DataVoucherSecondSection
